mat-tooltip-component {
  .mdc-tooltip {
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      z-index: 9999;
      background-color: #1a1c20;
      clip-path: polygon(50% 0, 0 50%, 50% 100%);
    }

    &::before {
      width: 10px;
      height: 10px;
      background-color: #28282b;
    }

    &[style*='transform-origin: left center'] {
      &::before,
      &::after {
        left: -4px !important;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: -5px !important;
      }
    }

    &[style*='transform-origin: right center'] {
      &::before,
      &::after {
        left: calc(100% - 6px) !important;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &[style*='transform-origin: center top'] {
      &::before,
      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        top: -4px !important;
      }

      &::before {
        top: -5px !important;
      }
    }

    &[style*='transform-origin: center bottom'] {
      &::before,
      &::after {
        top: calc(100% - 5px);
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);
      }
    }
  }

  .mat-mdc-tooltip-surface {
    font-size: 12px;
    font-weight: 500;
    background-color: #1a1c20;
    border: 1px solid #28282b;
    border-radius: 6px;
    padding: 5px 8px;
  }
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.cdk-overlay-container:has(.sheet-fan-area-menu-backdrop) {
  z-index: 999 !important;
}
