:root {
  --smart-wallet-accent: #5749ef;
  --smart-wallet-yellow: #ffc800;
  --smart-wallet-red: #ff3c3c;
  --smart-wallet-green: #20c083;

  --smart-wallet-gray-100: #a1a7b1;
  --smart-wallet-gray-200: #818694;
  --smart-wallet-gray-300: #626978;
  --smart-wallet-gray-400: #434b60;
  --smart-wallet-gray-500: #32323f;
  --smart-wallet-gray-600: #2a2d3a;
  --smart-wallet-gray-700: #24272f;
  --smart-wallet-gray-800: #1e2029;
  --smart-wallet-gray-900: #181a1f;

  --smart-wallet-head: #0e0e0f;
  --smart-wallet-footer: #111216;
  --smart-wallet-white: #ffffff;
  --smart-wallet-black: #000000;

  --smart-wallet-popup-gray-100: #535368;
  --smart-wallet-popup-gray-200: #2b2b37;
  --smart-wallet-popup-gray-300: #1a1b20;
  --smart-wallet-popup-gray-400: #1b1b22;
}
