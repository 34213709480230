@mixin title() {
  margin: 0 0 16px 0;
  color: var(--color-text);
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
}

@mixin sub-title() {
  color: var(--color-text);
  font-weight: 500;

  @media (min-width: 769px) {
    margin: 0 0 20px 0;
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    font-size: 15px;
    line-height: 17px;
  }
}

@mixin back-title() {
  margin: 0 0 16px 0;
  display: flex;
  div {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      .icon {
        border-color: var(--color-link);
      }
    }
    .icon {
      width: 25px;
      height: 25px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      border-radius: 5px;
      border: 1px solid #161618;
    }
  }
}

@mixin content-title() {
  @include title-font;
  color: var(--color-text);
  text-align: center;

  @media (min-width: 769px) {
    margin: 50px 10%;
    font-size: 54px;
    line-height: 56px;
  }

  @media (max-width: 768px) {
    margin: 30px 0;
    font-size: 36px;
    line-height: 38px;
  }
}

@mixin title-font() {
  font-weight: 400;
  font-family: 'Bebas Neue', sans-serif;
}

@mixin toggle-password-visibility() {
  position: relative;

  .icon {
    padding: 5px;
    color: var(--color-text);
    font-size: 13px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;

    &.icon-Closed-Eye {
      color: #55585c;
      font-size: 16px;
    }
  }
}

@mixin drop-down() {
  position: absolute;
  width: 140px;
  top: 45px;
  right: 0;
  background: #1f1f22;
  border-radius: 5px;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      padding: 11px 12px;
      font-size: 14px;
      font-weight: 400;
      border-bottom: 1px solid #27272c;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: var(--color-link);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    // .red {
    //   color: #f93535;

    //   &:hover {
    //     color: #b72828;
    //   }
    // }
  }
}

@mixin live-label() {
  width: 44px;
  height: 22px;
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ea2b2b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 0.42px;
}

@mixin form {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__field {
    width: 100%;
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-flow: column;
    }

    label {
      display: block;
      font-weight: 400;

      @media (min-width: 769px) {
        width: 178px;
        font-size: 15px;
        line-height: 44px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 6px;
      }
    }

    &-group {
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media (min-width: 769px) {
        max-width: 574px;
        margin: 0 0 16px 0;
      }

      @media (max-width: 768px) {
        margin: 0 0 18px 0;
      }

      input,
      select,
      textarea {
        width: 100%;
        height: 44px;
        margin: 0;
        padding: 0 15px;
        flex-grow: 1;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        border-radius: 5px;
        border: 1px solid #242428;
        background: #09090a;
        transition: all 0.2s;

        &.ng-invalid.ng-dirty {
          border: 1px solid var(--color-input-error);
        }

        &:disabled {
          background: #1d1d22;
        }
      }
    }
  }

  &__error {
    @include form-error;
  }
}

@mixin form-error {
  display: block;
  margin-top: 5px;
  color: #dc0e10;
  font-size: 13px;
  line-height: 16px;

  mark {
    padding: 0 4px;
    color: #fff;
    background: #ac1819;
    border-radius: 3px;
  }
}

@mixin form-modal {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  label {
    position: relative;
    display: block;
    margin-top: 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &__field {
    width: 100%;
    margin: 3px 0 0;
    padding: 10px 10px;
    display: block;
    position: relative;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border-radius: 5px;
    border: 1px solid #2f2f37;
    background: #161618;

    &:not(textarea) {
      height: 40px;
    }

    &.ng-invalid.ng-dirty {
      border: 1px solid var(--color-input-error);
    }

    &__error {
      display: block;
      margin-top: 5px;
      color: var(--color-input-error);
      font-size: 13px;
      line-height: 16px;

      mark {
        padding: 0 4px;
        color: #000;
        background: var(--color-input-error);
        border-radius: 3px;
      }
    }
  }

  app-button {
    width: 100%;
    margin-top: 20px;
  }
}

@mixin checkbox($checkboxSize, $textSize) {
  width: fit-content;
  min-height: $checkboxSize;
  padding-left: calc($checkboxSize + 8px);
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    width: $checkboxSize;
    height: $checkboxSize;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc($checkboxSize / 2);
    border-radius: 5px;
    background-color: #2c2c37;

    &::before {
      display: none;
    }

    &.error {
      border: 1px solid var(--input-error);
    }
  }

  .text {
    font-size: $textSize;
  }

  input:checked ~ .checkmark {
    background-color: var(--color-hightlight);

    &::before {
      display: block;
    }
  }
}

@mixin carousel-modal() {
  height: 100%;
  max-width: 992px;
  min-height: inherit;
  max-height: inherit;
  position: relative;

  @media (min-width: 769px) {
    width: 70vw;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }

  &-wrapper {
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }

  &-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      object-fit: contain;
    }

    .player-iframe-container {
      @include iframe-container();
    }

    &__empty {
      width: 100%;
    }
  }

  &-controls {
    @include carousel-controls();
  }

  &-preview {
    @include carousel-preview();
  }
}

@mixin carousel-preview() {
  width: 100%;
  margin: 8px 0 0;

  drag-scroll {
    width: 100%;

    ::ng-deep {
      .drag-scroll-content {
        display: flex !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0 0 5px 0;
      }
    }
  }

  &__item {
    margin-right: 1px !important;
    @include moments-border();

    &-wrapper {
      display: flex;
      flex-flow: column;
      height: 100%;
      padding: 2px;
      background: var(--color-bg);
      border-radius: 5px;
      user-select: none;

      .thumbnail {
        width: auto;
        height: 65px;
        min-width: 120px;
        max-width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}

@mixin video-wrapper() {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .play {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-light);
    border-radius: 50%;
    background-color: #fff;
    transition: all 1s;
    cursor: pointer;

    @media (min-width: 769px) {
      width: 40px;
      height: 40px;
      font-size: 12px;
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      font-size: 10px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .unlock {
    @include unlock-btn;
  }

  img {
    width: 100%;
    border-radius: 10px;
  }
}

@mixin unlock-btn() {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  padding: 6px;
  font-size: 12px;
  border-radius: 5px;
  background: #2f3137;
  cursor: pointer;

  .icon {
    margin-left: 6px;
  }
}

@mixin carousel-controls() {
  &-prev-wrapper,
  &-next-wrapper {
    position: absolute;
    width: 140px;
    max-width: 20%;
    height: 100%;
    cursor: pointer;
  }

  &-prev-wrapper {
    left: 0;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 10px 0 0 10px;
  }

  &-next-wrapper {
    right: 0;
    background: linear-gradient(-90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 0 10px 10px 0;
  }

  &-prev,
  &-next {
    width: 34px;
    height: 34px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-light);
    font-size: 12px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
    cursor: pointer;

    &.disabled {
      display: none;
    }

    &:hover {
      color: var(--color-light);
      background: rgba(255, 255, 255, 0.7);
    }
  }
  &-prev {
    left: 12px;

    span {
      margin-left: -2px;
    }
  }
  &-next {
    right: 12px;

    span {
      margin-left: 2px;
    }
  }
}

@mixin moments-border() {
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex-shrink: 0;
  padding: 1px;
  background: #2a2e35;
  border-radius: 5px;
  cursor: pointer;

  &.active {
    background: linear-gradient(108.14deg, #7000ff 0%, #ff00b8 100%);
    background-clip: padding-box;
  }
}

@mixin iframe-container() {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background-color: var(--color-bg);
  }
}

@mixin actions-row() {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding: 0 0 8px 0;

  h3 {
    @include sub-title();
    white-space: nowrap;

    @media (min-width: 769px) {
      margin: 0 12px 4px 0;
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-shrink: 0;
      margin: 0 12px 4px 0;
    }
  }

  &__show-more {
    margin: auto 0 7px auto;
    font-size: 16px;
  }
}

@mixin coming-soon() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  min-height: 132px;
  padding: 100px 10% 20px;
  border-radius: 10px;
  border: 1px solid #1b1b1b;
  background: #0c0c0e;
  background-image: url('~src/assets/icons/logo-shadow.svg');
  background-repeat: no-repeat;
  background-position: 50% 24px;

  span {
    color: var(--color-text-dark);
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
  }
}

@mixin faqs-list() {
  mat-expansion-panel::ng-deep {
    .mat-expansion-panel-body {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      h2 {
        margin: 0 0 20px 0;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
      }

      h3 {
        margin: 0 0 10px 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }

      ul {
        li {
          list-style: disc;
        }
      }

      ul,
      ol {
        @media (min-width: 769px) {
          padding-left: 30px;
        }
        @media (max-width: 768px) {
          padding-left: 25px;
        }

        li {
          padding-bottom: 10px;
        }

        ul,
        ol {
          @media (min-width: 769px) {
            padding: 10px 0 10px 50px;
          }
          @media (max-width: 768px) {
            padding: 10px 0 10px 30px;
          }
        }
      }

      img {
        margin: 20px 0;
        display: block;
        max-height: 512px;
        border-radius: 10px;
      }
    }
  }
}

@mixin reaction() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 28px;
  padding: 0 4px;
  border: 1px solid #1e1e20;
  border-radius: 5px;
  cursor: pointer;
}

@mixin reactions-popover() {
  display: flex;
  align-items: stretch;
  position: absolute;
  bottom: calc(100% + 7px);
  left: -10000px;
  background: #24272e;
  border-radius: 5px;
  z-index: 102;

  &.visible {
    &.position-center {
      left: 14px;
      transform: translateX(-50%);

      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.position-left {
      left: auto;
      right: 0;

      &::after {
        right: 7px;
      }
    }

    &.position-right {
      left: 0;

      &::after {
        left: 7px;
      }
    }

    &.position-center-right {
      @media (min-width: 1580px) {
        left: 14px;
        transform: translateX(-50%);
      }

      @media (max-width: 1579px) {
        left: 0;
      }

      &::after {
        @media (min-width: 1580px) {
          left: 50%;
          transform: translateX(-50%);
        }

        @media (max-width: 1579px) {
          left: 7px;
        }
      }
    }
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    display: block;
    top: 100%;
    margin-top: -1px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #24272e;
  }
}

@mixin close-icon() {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--color-text);
  font-size: 8px;
  border-radius: 5px;
  border: 1px solid #2a2c30;
  transition: all 0.25s;
  cursor: pointer;
  background: transparent;

  &:hover {
    color: var(--color-link);
  }
}

@mixin author-label() {
  padding: 5px 6px;
  display: flex;
  align-items: center;
  background: #151517;
  border-radius: 5px;

  img {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    margin-right: 6px;
  }

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
  }
}

@mixin share-btn() {
  height: 36px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 500;
  background: #151517;
  border-radius: 5px;
  border: 1px solid #292b2e;
  cursor: pointer;

  &:hover {
    border-color: #5e636d;
  }

  span {
    margin-right: 8px;
    font-size: 18px;
  }
}

@mixin tabs-list() {
  display: flex;
  align-items: center;

  li {
    button {
      display: flex;
      align-items: center;
      margin: 0 8px 0 0;
      color: #fff;
      font-weight: 400;
      border-radius: 100px;
      background: #151517;
      transition: all 1s;
      cursor: pointer;
      border: none;

      @media (min-width: 992px) {
        height: 40px;
        padding: 0 16px;
        font-size: 15px;
      }

      @media (max-width: 991px) {
        height: 30px;
        padding: 0 10px;
        font-size: 13px;
      }

      &.active {
        color: #343434;
        background: #fff;
      }
    }
  }
}

@mixin icon-button {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 4px;
  color: white;
  border: 1px solid #2a2e35;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;

  width: 24px;
  height: 24px;
  font-size: 12px;
}

@mixin reset-button-styles() {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

@mixin skeleton($width: auto, $height: auto, $borderRadius: unset) {
  width: $width;
  height: $height;
  border-radius: $borderRadius;
  background: linear-gradient(110deg, #626978 8%, #818694 18%, #626978 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}

@mixin smart-wallet-table() {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  border-style: hidden;
  box-shadow: 0 0 0 1px var(--smart-wallet-gray-600);

  @media (max-width: 768px) {
    box-shadow: none;
  }

  tr {
    border-bottom: 1px solid var(--smart-wallet-gray-600);
    border-radius: 12px;

    @media (max-width: 768px) {
      border-bottom: none;
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 24px;

      @media (max-width: 768px) {
        padding-left: 0;
      }
    }
  }

  th {
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: var(--smart-wallet-gray-100);
    text-shadow:
      -1px -1px 0 var(--smart-wallet-gray-600),
      1px -1px 0 var(--smart-wallet-gray-600),
      -1px 1px 0 var(--smart-wallet-gray-600),
      1px 1px 0 var(--smart-wallet-gray-600);
    text-transform: uppercase;
    text-align: left;
  }

  td {
    height: 80px;

    @media (max-width: 768px) {
      height: auto;
      padding-bottom: 20px;
    }
  }
}

@mixin smart-wallet-send-form() {
  :host {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    padding: 0 40px 0 100px;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin: 24px 0;

    @media (max-width: 768px) {
      gap: 8px;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border: 1px solid var(--smart-wallet-gray-500);
      border-radius: 6px;
      flex-shrink: 0;

      &:hover {
        text-decoration: none;
      }

      span {
        color: var(--smart-wallet-gray-300);
        font-size: 12px;
      }
    }

    &__text {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    &__wallet {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;

      img {
        width: 24px;
        height: 24px;
        border: 1px solid var(--smart-wallet-gray-400);
        border-radius: 50%;
      }

      span {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__links {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-left: auto;

      @media (min-width: 769px) {
        display: none;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border: 1px solid var(--smart-wallet-gray-500);
        border-radius: 5px;

        span {
          color: var(--smart-wallet-gray-300);
          font-size: 15px;
        }

        .icon-Question-2 {
          font-size: 14px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .content {
    display: flex;
    gap: 20px;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 688px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .send-disabled {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--smart-wallet-gray-600);
    background: linear-gradient(108deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.08) 100%);
    backdrop-filter: blur(10px);

    @media (max-width: 768px) {
      gap: 16px;
      padding: 20px 16px;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--smart-wallet-red);

      @media (max-width: 768px) {
        align-items: flex-start;
      }

      span {
        font-size: 18px;
      }

      .icon {
        @media (max-width: 768px) {
          margin-top: 3px;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
      }
    }

    &__text {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: var(--smart-wallet-white);

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    @media (max-width: 768px) {
      gap: 24px;
    }

    &.disabled {
      pointer-events: none;
      filter: grayscale(0.3) opacity(0.5);
    }

    .group {
      display: flex;
      flex-direction: column;
      gap: 24px;

      padding: 20px 24px;
      border-radius: 8px;
      border: 1px solid var(--smart-wallet-gray-600);

      @media (max-width: 768px) {
        gap: 0;
        padding: 0;
        border: none;
      }

      &__header {
        display: flex;
        align-items: center;
        gap: 12px;

        @media (max-width: 768px) {
          display: none;
        }

        &-text {
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
      }

      &__index {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 21px;
        background-color: var(--smart-wallet-white);
        border-radius: 6px;
        font-size: 12px;
        font-weight: 700;
        color: black;
      }

      &__input {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media (max-width: 768px) {
          gap: 24px;
        }

        .mobile-label {
          font-size: 14px;
          color: var(--smart-wallet-gray-100);
          line-height: normal;

          @media (min-width: 769px) {
            display: none;
          }
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (max-width: 768px) {
            gap: 8px;
          }
        }

        .text-input {
          padding: 19px 16px;
          border-radius: 8px;
          border: 1px solid var(--smart-wallet-gray-600);
          background-color: transparent;
          color: var(--smart-wallet-white);
          font-size: 16px;
          line-height: normal;

          @media (max-width: 768px) {
            padding: 12px 16px;
            font-size: 14px;
          }

          &::placeholder {
            color: var(--smart-wallet-gray-300);
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .amount-input {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 10px;

          input {
            width: 100%;
            padding-right: 120px;
          }

          &__info {
            position: absolute;
            display: flex;
            align-items: center;
            gap: 12px;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-size: 16px;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          &__coin {
            line-height: normal;
          }

          &__max {
            @include reset-button-styles;
            color: #3f72f4;
            line-height: normal;

            &[disabled] {
              color: #434b60;
              pointer-events: none;
              cursor: auto;
            }
          }
        }

        .amount-available {
          display: flex;
          align-items: center;
          gap: 4px;

          span {
            font-size: 16px;
            line-height: normal;

            @media (max-width: 768px) {
              font-size: 12px;
            }

            &:first-of-type {
              color: var(--smart-wallet-gray-300);
            }

            &:last-of-type {
              color: var(--smart-wallet-white);
            }
          }
        }

        .error {
          color: var(--smart-wallet-red);
          font-size: 16px;
          line-height: normal;

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      &.disabled {
        pointer-events: none;

        .group__header {
          &-text {
            color: var(--smart-wallet-gray-400);
          }
        }

        .group__index {
          background-color: var(--smart-wallet-gray-500);
          color: var(--smart-wallet-gray-100);
        }

        .group__input {
          display: none;
        }
      }
    }

    .confirm {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background-color: var(--smart-wallet-gray-700);
      border-radius: 8px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 20px 16px;
      }

      &__text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      &__amount,
      &__fee {
        color: var(--smart-wallet-gray-200);
        font-size: 14px;
        margin: 0;
        font-weight: 400;
      }

      &__amount {
        margin-bottom: 16px;
      }

      &__total {
        color: var(--smart-wallet-white);
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        margin-bottom: 12px;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      &__error {
        color: var(--smart-wallet-red);
        font-size: 14px;
        margin-top: 8px;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }

      &__button {
        @include reset-button-styles;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 16px;
        border-radius: 6px;
        background: var(--smart-wallet-accent);
        color: var(--smart-wallet-white);
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        flex-grow: 1;

        @media (max-width: 768px) {
          width: 100%;
        }

        &[disabled] {
          color: var(--smart-wallet-gray-300);
          background-color: rgba(255, 255, 255, 0.08);
          pointer-events: none;
        }
      }
    }
  }

  .faq {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }

    &__content {
      max-width: 452px;
    }

    &__header {
      margin: 0;
      margin-bottom: 32px;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }

    &__subheader {
      margin: 0;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        color: var(--smart-wallet-gray-200);
      }
    }
  }

  .recent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    margin-top: 60px;
    padding-bottom: 20px;

    @media (max-width: 768px) {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__header {
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      margin: 0;
    }
  }

  mat-select {
    color: var(--smart-wallet-white);

    mat-select-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }

        span {
          &:first-of-type {
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            color: var(--smart-wallet-white);

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          &:last-of-type {
            font-size: 14px;
            color: var(--smart-wallet-gray-300);
            line-height: normal;
          }
        }
      }
    }

    &[aria-expanded='true'] {
      ::ng-deep {
        .mat-mdc-select-arrow {
          transform: rotate(225deg);
          top: 2px;
        }
      }
    }
  }

  mat-option {
    padding: 12px;
    border-radius: 8px;
  }

  ::ng-deep .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: var(--mat-option-focus-state-layer-color) !important;
  }

  ::ng-deep .mat-mdc-option:focus.mdc-list-item:hover,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item:hover {
    background-color: var(--mat-option-hover-state-layer-color) !important;
  }

  ::ng-deep .mat-mdc-select-panel {
    --mat-select-panel-background-color: var(--smart-wallet-gray-800);
    --mat-option-selected-state-layer-color: var(--smart-wallet-gray-600);
    --mat-option-focus-state-layer-color: var(--smart-wallet-gray-600);
    --mat-option-hover-state-layer-color: var(--smart-wallet-gray-600);
    --mat-option-label-text-color: var(--smart-wallet-white);
    --mat-option-selected-state-label-text-color: var(--smart-wallet-white);
  }

  ::ng-deep .mat-mdc-select-placeholder {
    --mat-select-placeholder-text-color: var(--smart-wallet-gray-300);
  }

  ::ng-deep {
    div.mat-mdc-select-panel {
      padding: 12px !important;
      border-radius: 8px !important;
      max-height: 400px !important;
      margin-top: 6px;
    }

    .mat-pseudo-checkbox,
    .mat-mdc-select-arrow-wrapper svg {
      display: none !important;
    }

    .mat-mdc-select-arrow {
      position: relative;
      top: 0;
      border-right: 2px solid #5e636d;
      border-bottom: 2px solid #5e636d;
      width: 8px !important;
      height: 8px !important;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      transition: 0.15s all;
      box-sizing: border-box;

      @media (max-width: 768px) {
        top: -2px;
      }
    }

    .mat-mdc-select-trigger {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--smart-wallet-gray-600);

      @media (max-width: 768px) {
        padding: 8px 16px;
      }
    }

    .mdc-list-item__primary-text {
      display: block;
      width: 100%;
    }

    .select-coin,
    .select-chain {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }

      &__name,
      &__amount {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          &:first-of-type {
            font-weight: 700;
          }
          &:last-of-type {
            color: var(--smart-wallet-gray-300);
          }
        }
      }

      &__amount {
        span {
          text-align: right;
        }
      }
    }

    .select-chain {
      &__name {
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

@mixin switcher($size) {
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: -2;
    width: 100%;
    height: 100%;

    &:checked + label {
      &::before {
        left: 50%;
      }

      border-color: var(--color-status-success);
      background-color: var(--color-status-success);
    }

    &:disabled + label {
      background: #24242b;
      border: none;

      &:before {
        background-color: #36363e;
        top: 3px;
      }
    }
  }
  label {
    margin: 0;
    width: ($size * 2) + 4;
    height: $size + 6;
    position: relative;
    display: inline-block;
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid #fff;
    cursor: pointer;

    &::before {
      content: '';
      width: $size;
      height: $size;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: #fff;
      transition: 0.3s ease-in-out;
    }

    span {
      display: none;
    }

    &:hover {
      span {
        width: fit-content;
        display: block;
        padding: 8px 10px;
        background: #313137;
        border-radius: 5px;
        z-index: 100;
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
        margin-top: 15px;
        position: absolute;
        text-decoration: none;
        font-family: var(--font-text);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

@mixin buy-gmrx-modal() {
  mat-dialog-content {
    font-family: 'Roboto', sans-serif !important;
    background-color: #13151c;
    padding: 0 !important;
  }

  ::ng-deep {
    .mat-mdc-dialog-surface {
      border-radius: 8px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid #333b4d;

    .text {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }

    .close {
      @include reset-button-styles;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 8px;
      color: #62697c;
      border: 1px solid #333b4d;
      border-radius: 5px;
    }
  }

  .content {
    padding: 24px;
  }
}
